import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { customTranslateLoader } from './shared/services/customTranslateLoader';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { PushNotificationService } from './shared/services/push-notification.service';
export let options: Partial<IConfig> | (() => Partial<IConfig>);
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PushNotificationModule } from 'ng-push-notification';
import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
registerLocaleData(localePT);

@NgModule({
  declarations: [AppComponent],
  providers: [
    AuthGuardService,
    PushNotificationService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    // { provide: LOCALE_ID, useValue: 'pt-br' },
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoader,
        deps: [HttpClient]
      }
    }),
    PushNotificationModule.forRoot(/* Default settings here, interface PushNotificationSettings */),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot()
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

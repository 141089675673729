import { Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { TranslationService } from "../../../shared/services/translation.service";
import { environment } from "../../../../environments/environment";
import { StorageService } from "../../../shared/services/storage.service";
import { AuthService } from "../../../login/auth.service";
import { ErrorManagerService } from "../../../shared/services/error-manager.service";
import { OneColumnLayoutService } from "../../layouts/one-column/one-column.layout.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  public logo: string = "assets/images/favicon.png";
  public currentUser: any;
  public uri: string = environment.uri;
  themes = [
    {
      value: "default",
      name: this.translateService.getTranslate("title.light"),
    },
    {
      value: "dark",
      name: this.translateService.getTranslate("title.dark"),
    },
    {
      value: "cosmic",
      name: this.translateService.getTranslate("title.cosmic"),
    },
    {
      value: "corporate",
      name: this.translateService.getTranslate("title.amappzing"),
    },
  ];

  currentTheme = environment.defaultTheme;

  userMenu = [
    {
      data: 2,
      title: this.translateService.getTranslate("button.profile"),
      link: `${this.uri}/profile`,
      icon: "person-outline",
    },
    {
      data: 4,
      title: this.translateService.getTranslate("button.appearance"),
      link: `${this.uri}/setting`,
      icon: "color-palette-outline",
    },
    {
      data: 1,
      title: this.translateService.getTranslate("button.logOut"),
      icon: "corner-up-left-outline",
    },
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    public layout: OneColumnLayoutService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private translateService: TranslationService,
    public storage: StorageService,
    public router: Router,
    public service: AuthService,
    public errorManager: ErrorManagerService
  ) {
    this.menuService.onItemClick().subscribe((event) => {
      this.onContecxtItemSelection(event.item);
    });
  }

  onContecxtItemSelection(event: any) {
    if (event.data === 1) {
      this.layout.emitChangeEvent(true);
      this.service.sendPOST("logout", {}).subscribe(
        async (resp: any) => {
          if (resp.body) {
            resp = resp.body;
          }
          this.layout.emitChangeEvent(false);
          this.storage.logOut();
        },
        (error) => {
          this.layout.emitChangeEvent(false);
          let errorResponse = null;
          if (error.error) {
            errorResponse = error.error;
          }
          this.storage.logOut();
          this.errorManager.error(error.status, this.storage, errorResponse);
        }
      );

    }
  }

  ngOnInit() {
    this.currentUser = this.storage.getPersonalInfo();
    this.othersMenu();
    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.nick));
    const personal = JSON.parse(localStorage.getItem("personal_info"));
    if (personal && personal.business) {
      this.logo = personal.business.logo;
    }
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => themeName);

    if (
      this.currentUser &&
      this.currentUser.setting &&
      this.currentUser.setting.modeTheme
    ) {
      this.currentTheme = this.currentUser.setting.modeTheme;
      this.changeTheme(this.currentTheme);
    } else {
      this.changeTheme(this.currentTheme);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.router.navigate([`${this.uri}`]);
    return false;
    // this.menuService.navigateHome();
  }

  othersMenu() {
    const personalInfo = this.storage.getPersonalInfo();
    if (personalInfo && personalInfo.profile && personalInfo.profile.role) {
      if (personalInfo.profile.role === "ROLE_PROPERTY") {
        const setting = {
          data: 3,
          title: this.translateService.getTranslate("button.setting"),
          link: `${this.uri}/business-setting`,
          icon: "settings-2-outline",
        };
        const interestRateMenu = {
          data: 4,
          title: this.translateService.getTranslate("title.interestRate"),
          link: `${this.uri}/interest-rate`,
          icon: "activity-outline",
        };
        const accountBalanceAndWithdrawals = {
          data: 5,
          title: this.translateService.getTranslate("title.accountBalance"),
          link: `${this.uri}/account-balance`,
          icon: "percent-outline",
        };
        this.insertMenu(setting, 1);
        this.insertMenu(interestRateMenu, 3);
        this.insertMenu(accountBalanceAndWithdrawals, 4);
      }
    }
  }

  insertMenu(
    menu: { data: number; title: string; link: string; icon: string },
    order: number
  ) {
    if (this.userMenu.length > order && order >= 0) {
      this.userMenu.splice(order, 0, menu);
    }
  }

  search(event) {
    // console.log(event);
  }
}

import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from "@nebular/auth";
import { AuthGuardService as AuthGuard } from "./shared/services/auth-guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    /*loadChildren: './login/login.module#LoginModule'*/
  },
  {
    path: "pages",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },

  {
    path: "legal-information",
    loadChildren: () =>
      import("./legal-information/legal-information.module").then(
        (m) => m.LegalInformationModule
      ),
  },
  { path: "", redirectTo: "pages", pathMatch: "full" },
  { path: "**", redirectTo: "pages" },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
